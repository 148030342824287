<template>
  <modal
    :name="modalName"
    :width="600"
    :adaptive="true"
    :clickToClose="false"
    height="90%"
    class="modal--bank-account"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('bankForm.oneStep') }}
      </div>
      <section class="modal--container">
        <div class="title--text" v-if="$mq !== 'xs'">{{ $t('bankForm.oneStep') }}</div>
        <bank-account-form
          :showNotNowBtn="showNotNowBtn"
          :showPhoneNumberField="showPhoneNumberField"
          @postDone="postDone"
          @notNow="close"
        />
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
const BankAccountForm = () => import('@/components/bank-account/bank-account');

export default {
  name: 'input-bank-account-modal',
  props: {
    showPhoneNumberField: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      modalName: 'modal-input-bank-account',
      postDoneAction: null,
      postCancelAction: null,
      showNotNowBtn: false,
    };
  },
  components: {
    BankAccountForm,
  },
  methods: {
    async beforeOpen(event) {
      if (event.params) {
        if (event.params.postDoneAction !== null) {
          this.postDoneAction = event.params.postDoneAction;
        }
        if (event.params.postCancelAction !== null) {
          this.postCancelAction = event.params.postCancelAction;
        }
        if (event.params.showNotNowBtn !== null) {
          this.showNotNowBtn = event.params.showNotNowBtn;
        }
      }
    },
    postDone() {
      this.$emit('postDone');
      if (this.postDoneAction !== null) {
        this.postDoneAction();
      }
      this.$modal.hide(this.modalName);
    },
    close() {
      this.$emit('notNow');
      if (this.postCancelAction !== null) {
        this.postCancelAction();
      }
      this.$modal.hide(this.modalName);
    },
  },
};
</script>

<style></style>
